import { makeStyles } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import clsx from 'clsx';
import React from 'react';
import { ProductStockQuantity } from '../../@sprinx/knihovka-types';

export interface ProductStockQuantityPreviewProps extends StandardDivProps<ProductStockQuantityPreviewClassKey> {
  product: {
    stockQuantity?: ProductStockQuantity;
    taxonomies?: string[];
  };
}

export type ProductStockQuantityPreviewClassKey = 'root' | 'available';

const themeSettings = { name: 'ProductStockQuantityPreview' };
const useStyles = makeStyles(
  (theme) => ({
    root: {
      ...theme.typography.caption,
      // paddingLeft: theme.spacing(2),
      // marginTop: theme.spacing(4),
      // display: 'flex',
      // flexDirection: 'column',
      // justifyContent: 'center',
      // alignItems: 'flex-start',
      // borderLeftColor: theme.palette.divider,
      // borderLeftStyle: 'solid',
      // borderLeftWidth: 1,
      color: theme.palette.warning.main,
    },
    available: {
      color: theme.palette.success.main,
    },
    nonsaleable: {
      color: theme.palette.error.main,
    },
  }),
  themeSettings,
);

/**
 * @component
 */
const ProductStockQuantityPreview: React.FC<ProductStockQuantityPreviewProps> = React.forwardRef(
  ({ className, classes: pClasses, product }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const taxonomies = product.taxonomies || [];
    type Result = [boolean, 'none' | 'external' | 'eshop' | 'stores', number, number];
    const [isAvailable, stockType, quantity, storeCount] = React.useMemo(
      () => {
        const { detail = [] } = product.stockQuantity || {};

        return [
          // mam nieco na sklade eShopu => '99'
          // Skladem XX ks
          (x: Result): Result => {
            if (x[0]) return x;
            const a = detail.find((i) => i.warehouse === '99');

            if (a && a.quantity) {
              const rs = detail
                .filter((i) => i.warehouse !== '99' && i.warehouse !== 'ExterniSklad' && i.warehouse !== '01')
                .reduce((c, i) => (i.quantity ? [c[0] + 1, c[1] + i.quantity] : c), [0, 0]);
              return [true, 'eshop', a.quantity, rs[0]];
            }

            return x;
          },
          // mam nieco na predajniach
          // Skladem na 2 prodejnach
          (x: Result): Result => {
            if (x[0]) return x;

            const rs = detail
              .filter((i) => i.warehouse !== '99' && i.warehouse !== 'ExterniSklad')
              .reduce((c, i) => (i.quantity ? [c[0] + 1, c[1] + i.quantity] : c), [0, 0]);

            return rs[0] ? [true, 'stores', 0, rs[0]] : x;
          },
          (x: Result): Result => {
            if (x[0]) return x;
            const ext = detail.find((i) => i.warehouse === 'ExterniSklad');
            return ext && ext.quantity ? [false, 'external', ext.quantity, 0] : x;
          },
        ].reduce((r, fn) => fn(r), [false, 'none', 0, 0] as Result);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        // eslint-disable-next-line react-hooks/exhaustive-deps
        product.stockQuantity && product.stockQuantity.detail
          ? product.stockQuantity.detail.map((d) => `${d.warehouse}:${d.state}${d.quantity}`).join('#')
          : '$$none$$',
      ],
    );

    if (!taxonomies.includes('/eshop')) {
      return (
        <div ref={ref} className={clsx(classes.root, classes.nonsaleable, className)}>
          Tento produkt už neprodáváme
        </div>
      );
    }

    return (
      <div
        ref={ref}
        className={clsx(
          classes.root,
          {
            [classes.available]: isAvailable,
          },
          className,
        )}
      >
        {stockType === 'none' && (
          <>
            <div>Na dotaz</div>
            <div>Odesíláme za 3 dny</div>
          </>
        )}
        {stockType === 'eshop' && (
          <>
            <div>
              Skladem <FormattedQuantity quantity={quantity} storeCount={storeCount} />
            </div>
            <div>Odesíláme následující pracovní den</div>
          </>
        )}
        {stockType === 'stores' && (
          <>
            <div>Skladem na {storeCount === 1 ? '1 prodejně' : `${storeCount} prodejnách`}</div>
          </>
        )}
        {stockType === 'external' && (
          <>
            <div>
              Externí sklad <FormattedQuantity quantity={quantity} />
            </div>
            <div>Odesíláme za 3 dny</div>
          </>
        )}
      </div>
    );
  },
);

ProductStockQuantityPreview.displayName = 'ProductStockQuantityPreview';

export default ProductStockQuantityPreview;

const FormattedQuantity: React.FC<{ quantity: number | undefined; storeCount?: number | undefined }> = ({
  quantity,
  storeCount,
}) => {
  if (!quantity) {
    return null;
  }
  if (quantity >= 20) {
    return <span>více jak 20 ks</span>;
  }
  if (storeCount) {
    return (
      <span>
        {quantity} ks a další na {storeCount > 1 ? 'prodejnách' : 'prodejně'}
      </span>
    );
  }
  return <span>{quantity} ks</span>;
};
